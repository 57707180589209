import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { IAPIError } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  constructor(
    private readonly translateService: TranslateService
  ) { }

  public catchBadResponse(response: any): Observable<IAPIError> {
    let error: IAPIError = { code: '', message: '' };
    if (response instanceof HttpErrorResponse) {
      if (response.error.code) {
        error = { code: response.error.code, message: response.error.message };
      } else {
        const code = `OAUTH-${response.error.error}`;
        error = { code, message: this.translateService.instant(`BE_ERRORS.${code}`) };
      }
    } else if (response instanceof TimeoutError) {
      error = { code: 'FE-TIMEOUT_REQUEST', message: this.translateService.instant(`BE_ERRORS.FE-TIMEOUT_REQUEST`) };
    }
    return throwError(error);
  }
}
